<template>
  <v-flex shrink>
    <v-menu offset-y content-class="zg-select-border-radius">
      <template v-slot:activator="{ on, attrs }">
        <v-layout shrink v-on="on" v-bind="attrs">
          <ToolButton
            :icon="icon"
            :tooltip="tooltip"
            rightTooltip
            :active="active"
          ></ToolButton>
        </v-layout>
      </template>
      <slot></slot>
    </v-menu>
  </v-flex>
</template>

<script>
export default {
  props: {
    icon: {
      default: require("@/assets/editor/move.svg"),
    },
    tooltip: {
      type: String,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ToolButton: () => import("./ToolButton"),
  },
};
</script>